<template>
  <layout>
    <!-- <breadcrumb title="Оформить заявку" current="Оформить заявку" /> -->

    <div class="container">
      <div class="login-area message-area rn-section-gapTop">
        <div class="row g-5">
          <div
            class="col-lg-6"
            data-sal="slide-up"
            data-sal-delay="200"
            data-sal-duration="800"
            style="display: grid; align-content: space-around"
          >
            <h3 style="font-size: 48px">
              Активировать скидку
              <br />
              50% и бесплатный <br />
              пробный период
            </h3>
          </div>

          <div
            class="col-lg-6"
            data-sal="slide-up"
            data-sal-delay="200"
            data-sal-duration="800"
          >
            <div class="form-wrapper-one registration-area">
              <h3 class="mb--30">
                <!-- Оставить заявку -->
              </h3>
              <contact-form />
            </div>
          </div>
        </div>
      </div>
    </div>
  </layout>
</template>

<script>
import Layout from "@/components/layouts/Layout";
import Breadcrumb from "@/components/breadcrumb/Breadcrumb";
import SalScrollAnimationMixin from "@/mixins/SalScrollAnimationMixin";
import ContactForm from "@/components/contact/ContactForm";

export default {
  name: "Contact",
  components: { ContactForm, Breadcrumb, Layout },
  mixins: [SalScrollAnimationMixin],
};
</script>