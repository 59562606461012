<template>
  <form class="rwt-dynamic-form" id="contact-form">
    <!-- Имя -->
    <div class="mb-5">
      <label for="contact-name" class="form-label">Имя</label>
      <input
        name="contact-name"
        id="contact-name"
        type="text"
        v-model="formData.name"
      />
      <span
        v-if="v$.formData.name.$error"
        class="text-danger mt-2 d-inline-block"
      >
        Обязательные данные
      </span>
    </div>

    <!-- Телефон и Email -->
    <div class="mb-5" style="display: flex; gap: 8px">
      <div class="col-md-6 col-sm-6">
        <label for="contact-phone" class="form-label">Телефон</label>
        <input
          id="contact-phone"
          name="contact-phone"
          type="text"
          v-model="formData.phone"
          @input="formatPhone"
        />
        <span
          v-if="v$.formData.phone.$error"
          class="text-danger mt-2 d-inline-block"
        >
          Номер должен быть длиной от 11 символов
        </span>
      </div>

      <div class="col-md-6 col-sm-6">
        <label for="contact-email" class="form-label">Почта</label>
        <input
          id="contact-email"
          name="contact-email"
          type="email"
          v-model="formData.email"
        />
        <span
          v-if="v$.formData.email.$error"
          class="text-danger mt-2 d-inline-block"
        >
          Некорректный email
        </span>
      </div>
    </div>

    <!-- Название организации -->
    <div class="mb-5">
      <label for="subject" class="form-label">Название организации</label>
      <input
        id="subject"
        name="subject"
        type="text"
        v-model="formData.subject"
      />
      <span
        v-if="v$.formData.subject.$error"
        class="text-danger mt-2 d-inline-block"
      >
        Обязательные данные
      </span>
    </div>

    <!-- Кнопка отправки -->
    <button
      name="submit"
      type="submit"
      class="btn btn-primary"
      @click.prevent="submitForm"
      :disabled="loading"
    >
      <span v-if="!loading">Активировать</span>
      <span v-else style="display: flex; align-items: center; gap: 4px">
        <i class="spinner-border spinner-border-sm" role="status"></i>
        Загрузка
      </span>
    </button>

    <!-- Сообщения об ошибках и успехе -->
    <p v-if="submitted" class="mt-4 font-14 text-success">Успешно!</p>
    <p
      v-if="errorMessage || errorMessageEmail"
      class="mt-4 font-14 text-danger"
    >
      {{ errorMessage || errorMessageEmail }}
    </p>
  </form>
</template>
  
  
  
  <script>
import axios from "axios";
import useVuelidate from "@vuelidate/core";
import { required, minLength } from "@vuelidate/validators";

// Регулярное выражение для валидации email
const emailRegexp =
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu;

export default {
  name: "ContactForm",
  data() {
    return {
      v$: useVuelidate(),
      formData: {
        name: "",
        phone: "",
        email: "",
        subject: "",
      },
      submitted: false,
      errorMessage: "", // Для отображения ошибок
      errorMessageEmail: "", // Для email
      loading: false, // Для состояния загрузки
    };
  },
  validations() {
    return {
      formData: {
        name: { required },
        phone: {
          required,
          minLength: minLength(11), // Минимальная длина 11 символов
        },
        email: {
          required,
          customEmail: (value) =>
            emailRegexp.test(value) || "Некорректный email",
        },
        subject: { required },
      },
    };
  },
  methods: {
    formatPhone() {
      let phone = this.formData.phone.replace(/\D/g, ""); // Удаляем все символы, кроме цифр
      const length = phone.length;

      if (length < 2) {
        phone = "+7"; // Начало номера
      } else if (length <= 4) {
        phone = "+7 (" + phone.slice(1); // Код страны и до 3 цифр кода города
      } else if (length <= 7) {
        phone = "+7 (" + phone.slice(1, 4) + ") " + phone.slice(4); // Полный код города и часть номера
      } else if (length <= 9) {
        phone =
          "+7 (" +
          phone.slice(1, 4) +
          ") " +
          phone.slice(4, 7) +
          "-" +
          phone.slice(7); // Добавляем первую часть номера
      } else {
        phone =
          "+7 (" +
          phone.slice(1, 4) +
          ") " +
          phone.slice(4, 7) +
          "-" +
          phone.slice(7, 9) +
          "-" +
          phone.slice(9, 11); // Полный формат номера
      }

      this.formData.phone = phone; // Устанавливаем отформатированный номер
    },
    async submitForm() {
      // Очистка предыдущих сообщений об ошибках
      this.errorMessage = "";
      this.errorMessageEmail = "";
      this.submitted = false;
      this.loading = true; // Устанавливаем состояние загрузки

      // Запускаем валидацию vuelidate
      await this.v$.$validate();

      // Проверка на ошибки от vuelidate
      if (this.v$.$error) {
        this.errorMessage =
          "Пожалуйста, заполните все обязательные поля корректно.";
        this.loading = false; // Сбрасываем состояние загрузки
        return;
      }

      // Явная проверка email через регулярное выражение
      if (!emailRegexp.test(this.formData.email)) {
        this.errorMessageEmail = "Некорректный email";
        this.loading = false; // Сбрасываем состояние загрузки
        return;
      }

      // Если валидация прошла, отправляем запрос
      try {
        const data = {
          name: this.formData.name,
          phone: "+" + this.formData.phone.replace(/\D/g, ""), // Убираем символы кроме цифр
          email: this.formData.email,
          dealName: this.formData.subject,
          dealPrice: 15000, // Жестко задаем цену сделки
        };

        // Отправка данных на сервер
        const response = await axios.post(
          "https://crm.highnet.host/api/create-deal",
          data,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        // Если запрос успешен
        this.submitted = true;
        console.log(response.data);

        // Очистка полей формы
        this.formData.name = "";
        this.formData.phone = "";
        this.formData.email = "";
        this.formData.subject = "";
        this.v$.$reset(); // Сброс ошибок валидации
      } catch (error) {
        // Обработка ошибок запроса
        console.error("Ошибка при отправке данных:", error);
        this.errorMessage =
          "Ошибка при отправке данных. Пожалуйста, попробуйте снова.";
      } finally {
        // Сбрасываем состояние загрузки
        this.loading = false;
      }
    },
  },
};
</script>
  

  